.spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  pointer-events: none;
}

.spinner .MuiCircularProgress-circle {
  color: white;
}