.workorder-list {
  flex: 1;
}

.circle {
  height: 1.875rem;
  width: 1.875rem;
  min-height: 1.875rem;
  min-width: 1.875rem;
  border-radius: 50%;
}