.workorder-attachments .MuiButton-endIcon,
.workorder-notes .MuiButton-endIcon,
.linked-workorders .MuiButton-endIcon {
  position: absolute;
  right: 1rem;
  z-index: 2000;
}

.workorder-note {
  padding-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.workorder-note:last-child {
  margin-bottom: 0;
}