#map {
  flex: 1;
  height: 100%;
}

#map .maplibregl-ctrl-bottom-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


#map .maplibregl-ctrl-bottom-right>* {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

#map details.maplibregl-ctrl-attrib {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0;
}

#map .maplibregl-ctrl-bottom-right .maplibregl-ctrl-group {
  opacity: 0.9;
  background-color: #1D4B6D;
  overflow: hidden;
}

#map .maplibregl-ctrl-zoom-in,
#map .maplibregl-ctrl-zoom-out {
  width: 2.5rem;
  height: 2.5rem;
}

#map .maplibregl-ctrl-bottom-right .maplibregl-ctrl-group .maplibregl-ctrl-zoom-in span:hover,
#map .maplibregl-ctrl-bottom-right .maplibregl-ctrl-group .maplibregl-ctrl-zoom-out span:hover {
  background-color: #11224B;
}

#map .maplibregl-ctrl-bottom-right .maplibregl-ctrl-group .maplibregl-ctrl-zoom-in span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E")
}

#map .maplibregl-ctrl-bottom-right .maplibregl-ctrl-group .maplibregl-ctrl-zoom-out span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E")
}

#map .maplibregl-ctrl-attrib-inner {
  color: rgba(0, 0, 0, .75);
}