.side-panel {
  width: 32rem;
  height: 100%;
  position: relative;
  /* background-color: #12161B;
  color: #fff; */
  display: flex;
  flex-direction: column;
}

/* .side-panel .MuiButtonBase-root {
  color: #fff;
} */