.MuiPaper-elevation8 {
  box-shadow: 0px 5px 8px -2px rgb(0 0 0 / 30%), 0px 8px 10px 1px rgb(0 0 0 / 28%), 0px 3px 14px 2px rgb(0 0 0 / 24%) !important;
}

.MuiAlert-root {
  cursor: pointer !important;
}

@keyframes alert-timer {
  from {
    right: 100%;
  }

  to {
    right: 0;
  }
}


.alert-timeout {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: rgba(255, 255, 255, 0.75);
  animation-name: alert-timer;
  animation-timing-function: linear;
}

.MuiAlert-message {
  display: flex;
  align-items: center;
  overflow: visible !important;
}

.MuiAlert-message p {
  font-size: 0.85rem;
  white-space: nowrap;
}

.MuiAlert-message button {
  font-size: 0.85rem;
  white-space: nowrap;
  margin: -1rem -0.5rem -1rem 1rem;
}
