.alert-item {
  position: relative;
  width: 100%;
  min-height: 3rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  background-color: orange;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
}

.alert-item.highlighted {
  animation: blink 1s infinite;
}

.alert-icon {
  width: 3rem;
  min-width: 3rem !important;
  height: calc(3rem + 2px);
  margin: 0;
  padding: 0;
}

.alert-icon.highlighted {
  animation: blink 1s infinite;
}

.alert-icon svg {
  transition: all 0.4s linear;
}

.alert-icon.highlighted svg {
  transition: all 0.1s linear;
}

.alert-icon .target {
  opacity: 0;
  position: absolute;
  width: 15%;
  height: 15%;
  border-radius: 50%;
  background: radial-gradient(circle at 50%,
      rgb(194, 36, 36),
      rgb(194, 36, 36) 40%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0) 100%);
}

.alert-icon.highlighted .target {
  animation: blink-enemy 3s infinite;
  opacity: 0;
}

.alert-icon .target-1 {
  top: 65%;
  left: 45%;
}


.alert-icon .target-2 {
  top: 27%;
  left: 27%;
}

.alert-icon .target-3 {
  top: 33%;
  left: 65%;
}

@keyframes blink {
  0% {
    background-color: orange;
  }

  50% {
    background-color: hsl(27, 100%, 50%);
  }

  100% {
    background-color: orange;
  }
}

@keyframes blink-enemy {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0.25;
  }

  100% {
    opacity: 1;
  }
}