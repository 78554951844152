@property --num {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

#current-xp {
  transition: --num 1s;
  counter-reset: num var(--num);
}

#current-xp::after {
  content: counter(num);
}