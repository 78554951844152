.tooltip {
  display: none;
  position: fixed;
  z-index: 100;
  padding: 0.5rem;
  transform: translate(-50%, calc(-100% - 1rem));
  line-height: 1rem;
  font-size: 1rem;
}

#heatmap .cell {
  cursor: pointer;
}

#heatmap-wrapper .MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}