html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
#root {
  width: 100vw;
  height: 100svh;
}

@font-face {
  font-family: 'Atkinson-Hyperlegible';
  src: local('Atkinson-Hyperlegible'), url(./assets/fonts/Atkinson-Hyperlegible-Regular-102.woff) format('woff');
}

@font-face {
  font-family: 'Atkinson-Hyperlegible';
  font-weight: bold;
  src: local('Atkinson-Hyperlegible'), url(./assets/fonts/Atkinson-Hyperlegible-Bold-102.woff) format('woff');
}

@font-face {
  font-family: 'Atkinson-Hyperlegible';
  font-style: italic;
  src: local('Atkinson-Hyperlegible'), url(./assets/fonts/Atkinson-Hyperlegible-Italic-102.woff) format('woff');
}

@font-face {
  font-family: 'Atkinson-Hyperlegible';
  font-weight: bold;
  font-style: italic;
  src: local('Atkinson-Hyperlegible'), url(./assets/fonts/Atkinson-Hyperlegible-BoldItalic-102.woff) format('woff');
}


/* Custom scrollbar - Firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 transparent;
}

/* Custom scrollbar - Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  margin-right: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  border-radius: 20px;
  border: 3px solid #a0a0a0;
}

input {
  color-scheme: dark;
}